import React from "react"
import styled from "styled-components"

const Board = styled.div`
  background: white;
  box-shadow: 0 20px 90px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  display: grid;
  max-width: 800px;
  margin: 20px auto;
  grid-template-columns: repeat(4, auto);
  grid-gap: 5px;
  align-items: center;
  justify-items: center;
  cursor: default;
  transform: scale(0.9, 0.9);

    
`
const Category = styled.div`
  margin: 20px;
  font-size: 24px;
  padding: 0;
`
const Indicator = styled.div`
  margin: 5px 0px 0px 3px;
  width: 18px;
  height: 18px;
  background-image: url(${props => props.image});
  background-size: 18px;
  
`

const Title = styled.h3`
  margin: 0px;
  color: gray;
  font-size: 18px;
  font-weight: 500;
  padding: 0;
`

const Value = styled.h3`
  margin: 0px;
  color: #2c2c2e;;
  font-size: 44px;
  padding: 0;
`

const data = [
  {
    title: "Planned",
    icon: "https://clement-portfolio.s3.eu-west-2.amazonaws.com/icons/planned.png",
    value: "6",
  },
  {
    title: "In Progress",
    icon: "https://clement-portfolio.s3.eu-west-2.amazonaws.com/icons/inProgress.png",
    value: "8",
  },
  {
    title: "Completed",
    icon: "https://clement-portfolio.s3.eu-west-2.amazonaws.com/icons/completed.png",
    value: "10",
  },
  {
    title: "Projects Total",
    icon: "",
    value: "24",
  },
]

const Tracker = props => (
  <Board>
    {data.map(data => (
      <Category>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Value>{data.value}</Value>
          <Indicator image={data.icon} />
        </div>
        <Title>{data.title}</Title>
      </Category>
    ))}
  </Board>
)

export default Tracker
