import React from 'react'
import './card.css'
import { Link } from 'gatsby'


const Card = props => (
    <Link className="Card" to={props.link}>
        <img src={props.image} />
        <h3>{props.title}</h3>
        <p>{props.text}</p>
        <p>{props.status}</p>
    </Link>
)

export default Card