/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {

  return (
    <>
        <Header />
        <main>{children}</main>
        <footer className="Footer">
          © {new Date().getFullYear()}, Clement Godard
        </footer>
      
    </>
  )
}


export default Layout
