import React from "react"
import styled from "styled-components"


const OutsideWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
`

const InnerWrapper = styled.div`
  background: url(${props => props.img});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
  padding: 50px;
  position: fixed;
  

  transition: 1.5s cubic-bezier(0.4, 0.8, 0.2, 1);
  width: 50vh;
  align-self: center;
  
  opacity: ${props => (props.show ? "1" : "0")};
  transform: ${props =>
    props.show ? "translateY(0vh)" : "translateY(-100vh)"};
  z-index: 999;
`

const ModalHeader = styled.div`
  height: 18vh;
  line-height: 40px;
  padding: 25px 20px;
  text-align: right;

  h3 {
    font-size: 22px;
    font-weight: 600;
    color: black;
    float: left;
    margin-top: 30%;
    padding: 0;
  }

  span {
    background-image: url(${props => props.img});

    font-size: 75px;
    cursor: pointer;
  }

  img {
    transition: 0.5s cubic-bezier(0.4, 0.8, 0.2, 1);
  }

  img:hover {
    transform: scale(1.3);
    transition: 0.5s cubic-bezier(0.4, 0.8, 0.2, 1);
  }
`

const ModalBody = styled.div`
  color: #45484a;
  font-weight: 500;
  padding: 10px 15px;
  text-align: center;
`



const ModalActionBtn = styled.div`
  margin: 30px auto;
  padding-bottom: 15px;
  width: 120px;
  height: 36px;

  img {
    width: 120px;
    height: 36px;
    transition: 0.5s cubic-bezier(0.4, 0.8, 0.2, 1);
  }

  img:hover {
    transform: scale(1.5);
    transition: 0.5s cubic-bezier(0.4, 0.8, 0.2, 1);
  }
`

const modal = props => {
  return (
    <OutsideWrapper>
      <InnerWrapper img={"https://clement-portfolio.s3.eu-west-2.amazonaws.com/modal-background.png"} show={props.show}>
        {/* <img src={} /> */}
        <ModalHeader>
          <h3>Let's talk!</h3>
          <span onClick={props.close}>
            <img src={"https://clement-portfolio.s3.eu-west-2.amazonaws.com/Close-btn.png"} width={25} />
          </span>
        </ModalHeader>
        <ModalBody>
          <p>{props.children}</p>
          <ModalActionBtn>
            <a href="https://www.google.com">
              <img src={"https://clement-portfolio.s3.eu-west-2.amazonaws.com/Github-dark.png"} />
            </a>
          </ModalActionBtn>
          <ModalActionBtn>
            <a href="https://www.google.com">
              <img src={"https://clement-portfolio.s3.eu-west-2.amazonaws.com/Linkedin-blue.png"} />
            </a>
          </ModalActionBtn>
          <ModalActionBtn>
            <a href="https://www.google.com">
              <img src={"https://clement-portfolio.s3.eu-west-2.amazonaws.com/Mail-icon.png"} />
            </a>
          </ModalActionBtn>
        </ModalBody>
      </InnerWrapper>
    </OutsideWrapper>
  )
}

export default modal
