import React from "react"

const Wave = () => (
  <svg width="100%" height="185px" xmlns="http://www.w3.org/2000/svg">
    <path fill="white">
      <animate
        repeatCount="indefinite"
        fill="freeze"
        attributeName="d"
        dur="10s"
        values="M0,58.7639764 C196.876401,9.67310502 396.043067,-8.66022832 597.5,3.76397639 C899.685399,22.4002834 1271.70445,222.018376 1658.5,136.263976 C2045.29555,50.5095765 2480.64842,34.2639764 2560,34.2639764 L2560,192.263976 L0.367200574,192.263976 L0,58.7639764 Z;
                    
                    M0,59.0950203 C201.543067,98.4948313 403.043067,124.406839 604.5,136.831044 C906.685399,155.467351 1267.70445,120.585444 1654.5,34.8310439 C2041.29555,-50.923356 2480.64842,122.331044 2560,122.331044 L2560,192.59502 L0.367200574,192.59502 L0,59.0950203 Z;

                    M0.132799426,125.007722 C201.342534,119.963585 402.675867,123.667473 604.132799,136.119385 C906.318198,154.797253 1244.6328,104.507722 1666.6328,125.007722 C2088.6328,145.507722 2480.28122,121.587048 2559.6328,121.587048 L2559.6328,192.007722 L2.27373675e-13,192.007722 L0.132799426,125.007722 Z;
                    
                    M0,58.7639764 C196.876401,9.67310502 396.043067,-8.66022832 597.5,3.76397639 C899.685399,22.4002834 1271.70445,222.018376 1658.5,136.263976 C2045.29555,50.5095765 2480.64842,34.2639764 2560,34.2639764 L2560,192.263976 L0.367200574,192.263976 L0,58.7639764 Z;"
      />
    </path>
  </svg>
)

export default Wave



