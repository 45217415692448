import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"
import logo from "../images/designcode-featured.png"
import Modal from "../components/modal"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasScrolled: false,
      isShowing: false,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 50) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  openModalHandler = () => {
    this.setState({
      isShowing: true,
    })
  }

  closeModalHandler = () => {
    this.setState({
      isShowing: false,
    })
  }

  render() {
    return (
      <div>
       <Modal
          className="modal"
          show={this.state.isShowing}
          close={this.closeModalHandler}
        >
          Maybe aircrafts fly very high because they don't want to be seen in
          plane sight?
        </Modal>

         {this.state.isShowing ? (
            <div onClick={this.closeModalHandler} className="back-drop" style={{ "transition": "5s cubic-bezier(0.2, 0.8, 0.2, 1)"}}></div>
          ) : null}

        <div
          className={
            this.state.hasScrolled ? "Header HeaderScrolled" : "Header"
          }
        >
          
          <div className="HeaderGroup">
            <Link to="/">
              <img src={logo} width="30" />
            </Link>
            <Link to="/courses">Blog</Link>
            <Link to="/dowloads">Portfolio</Link>
            <Link to="/workshops">Dowloads</Link>

            <button onClick={this.openModalHandler}>Contact</button>
          </div>
        </div>
       
      </div>
    )
  }
}

export default Header
